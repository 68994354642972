
import { Bar } from 'vue-chartjs';
import { HorizontalBar } from 'vue-chartjs'

        export default {
            extends: HorizontalBar,

            props:['chartData','Options'],

            mounted () {
                this.renderChart(this.chartData, this.Options);
            }
        }